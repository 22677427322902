import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withInterceptorsFromDi, } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ServiceWorkerModule } from '@angular/service-worker';
import { ExcelService } from '@app/@shared/services/export/export.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { MessageModule } from 'primeng/message';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageKey } from './@shared/services/storage/storage-key';
import { StorageService } from './@shared/services/storage/storage.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auth.guard';
import { RefreshTokenGuard } from './guards/refresh-token.guard';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DomainInfo } from './models/domain-info.model';
import { TimezoneConverterPipeModule } from './modules/shift-types/pipes/timezone-converter-pipe.module';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function initializeApp(
  httpClient: HttpClient,
  storageService: StorageService
) {
  if (!environment.featuresAvailability.domainValidation) return;

  return () =>
    httpClient
      .get<DomainInfo>(environment.apiUrl + 'domain-info')
      .pipe(take(1))
      .subscribe((response: DomainInfo) => {
        if (response.isValidDomain) {
          storageService.setData(StorageKey.domainInfo, response);
        } else if (location.hostname !== 'localhost') {
          window.open(environment.signupURL, '_self');
        }
      });
}
    @NgModule({
      declarations: [AppComponent],
      imports: [
        BrowserModule,
        ServiceWorkerModule.register('./ngsw-worker.js', {
          enabled: environment.production,
        }),
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        EditorModule,
        DynamicDialogModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        MessageModule,
        ToastModule,
        ProgressBarModule,
        CarouselModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DropdownModule,
        TimezoneConverterPipeModule
      ],
      providers: [
        provideHttpClient(),
        MessageService,
        DialogService,
        ConfirmationService,
        RefreshTokenGuard,
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
          provide: APP_INITIALIZER,
          useFactory: initializeApp,
          multi: true,
          deps: [HttpClient, StorageService],
        },
        ExcelService,
        provideHttpClient(withInterceptorsFromDi()),
      ],
      bootstrap: [AppComponent],
    })
export class AppModule {}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}