import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      if (request.url.startsWith('/org')) {
        request = this.getOriginalUrl(request);
      }
    }

    return next.handle(request);
  }

  /**
   * Map the url prefix to the original url.
   * @param request incoming Http Request
   * @returns HttpRequest with the original url
   */
  private getOriginalUrl(request: HttpRequest<any>): HttpRequest<any> {
    /**
     * incoming url expected to be in format /org/{{service name}}
     * will split the url by '/'
     * first element in the string array should be ''
     * second element in the string array should be 'org'
     * third element should be 'service Name'
     */

    const requestPaths = request.url.split('/').slice(1);

    const prefixApiMap: any = {
      token: environment.ahoyAuthUrl,
      auth: environment.authAuthApi,
      transactions: environment.transactionsUrl,
      merchant: environment.merchantUrl,
      profile: environment.profileUrl,
    };

    if (
      requestPaths.length < 1 ||
      requestPaths[0] !== 'org' ||
      !prefixApiMap[requestPaths[1]]
    ) {
      // invalid request
      return request;
    }

    if (request.url.startsWith('/org/token')) {
      request = request.clone({
        url:
          environment.ahoyAuthUrl +
          request.url.replace(
            '/org/token',
            `/${environment.activeDirectoryUrl}`
          ),
      });
    } else {
      request = request.clone({
        url:
          prefixApiMap[requestPaths[1]] +
          request.url.replace(`/org/${requestPaths[1]}`, ''),
      });
    }

    return request;
  }
}
